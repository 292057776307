h2 {
	color: #4f4f4f;
	letter-spacing: 1px;
	font-weight: 400;
	margin: 0 0 1rem 0;
}

.buttonGroup {
	display: flex;
	flex-direction: row;
	margin-bottom: 1rem;
	.buttonDisplay {
		display: flex;
		flex-direction: column;
		margin-bottom: 1.25rem;
		flex-shrink: 0;
		min-width: 30%;
		width: auto;
		& > * {
			align-self: flex-start;
		}
		& > code {
			margin-bottom: 1rem;
		}
		&:not(:first-child) {
			margin-left: 3rem;
		}
	}
}

footer {
	margin-top: 4rem;
	color: #a9a9a9;
	span {
		font-weight: bold;
	}
}
