.nav {
	background: #fafbfd;
	padding: 2rem;
}

.brand {
	font-size: 120%;
	font-weight: 600;
	letter-spacing: 1px;
	span {
		color: #f7542e;
	}
}

nav {
	height: 30%;
	margin-top: 50%;
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		height: 100%;
		font-size: 110%;
		letter-spacing: 1px;
		li {
			color: #9e9e9e;
			transition: all 0.2s;
			cursor: pointer;
			&.active,
			&:hover {
				color: #090f31;
			}
			&.active {
				font-weight: bold;
			}
		}
	}
}
