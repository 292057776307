body {
	font-family: sans-serif;
	font-size: 14px;
}

.content {
	display: flex;
}

.content > * {
	padding: 2rem;
}

.content > *:nth-child(2) {
	margin: 0.5rem 3rem;
}
